// Fonts
export const Helvetica = '"Helvetica Neue", "Helvetica", "Arial", sans-serif';

// Sizes
export const xsmall = '5px';
export const small = '10px';
export const medium = '20px';
export const large = '40px';
export const xlarge = '80px';
export const xxlarge = '160px';
export const headerHeight = '5rem';
export const xsmallRem = '2rem';
export const smallRem = '4rem';

// Grayscale
export const AstroGranite = '#1a2e3b';
export const AlmostBlack = '#121212';
export const Black = '#000';
export const VimeoAlt = '#3a5161';
export const BlueGray = '#3a5161';
export const RegentGray = '#8699a6';
export const BlueBayoux = '#496073';
export const SoutherlySky = '#b3bfc8';
export const Porcelain = '#e3e8e9';
export const StripePlaceholder = '#7c93a6';
export const Plaster = '#eef1f2';
export const Paste = '#f6f7f8';
export const White = '#fff';
export const DarkDark = '#2e2e2e';
export const DarkGrey = '#515151';
export const DarkerGrey = '#657987';
export const JungleMist = '#b3bfc8';
export const Tangaroa = '#192d39';
export const IronHeart = '#666666';
export const DarkBlue = '#23313b';
export const LightGray = '#b5b5b5';

// Reds
export const DangerRed = '#db2e2e';
export const PalePink = '#ffeded';
export const SunsetOrange = '#ff4d4d';

// Yellow
export const DarkGoldenrod = '#ffb21e';

// Blues
export const VimeoBlue = '#00adef';
export const Foam = '#e5f7fd';
export const OTTDarkBlue = '#111c25';
export const BlueMagenta = '#392d86';

// Variants
export const VimeoBlueDarkened = '#0088cc';
export const VimeoBlueLightened = '#36C5FC';
export const PistachioDarkened = '#5a9e02';
export const Pistachio = '#7FC400';
export const SunsetOrangeDarkened = '#d93636';

// Legacy Gray
export const OTTGray1 = '#F0F2F4';
export const OTTGray5 = '#5E7887';

// Establishes a baseline unit from which to derive all padding / margins
// 8 is the greatest common denominator of margin / padding values requested by our designer
// 5 is the greatest common denominator for default sizing
export const octUnit = 8;
export const rem = (value: number) => `${value / 16}rem`;
export const divideOct = (divisor: number) => `${octUnit / divisor / 16}rem`;
export const multiplyOct = (factor: number) => `${(octUnit * factor) / 16}rem`;

export const quintUnit = 5;
export const divideQuint = (divisor: number) =>
  `${quintUnit / divisor / 16}rem`;
export const multiplyQuint = (factor: number) =>
  `${(quintUnit * factor) / 16}rem`;

// beziers
export const easeOutQuad = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
export const easeInQuad = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
export const easeInoutQuad = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';

export const easeOutCubic = 'cubic-bezier(0.215, 0.61, 0.355, 1)';
export const easeInCubic = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
export const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1)';

// Focus color
export const FocusBlue = 'rgba(54, 197, 252, 0.667)';

export const standardWidth = `
    padding: 0px ${rem(20)};
    align-self: center;
    margin: 0 auto;
    max-width: ${rem(1320)};
`;
